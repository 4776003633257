<template>
  <div id="register-email">
    <AppContainer :useBackground="true">
      <HeaderCommon :use-logo="true">
        <template v-slot:headerButton>
          <ButtonBack class="btn-back position-absolute" />
        </template>
      </HeaderCommon>
      <div class="email-register">
        <img
          class="logo"
          alt="logo-login-email"
          src="@/assets/image/icon-mail.svg"
        />
        <div class="email-register__content">
          <ValidationObserver v-slot="{ handleSubmit }" ref="anyName">
            <form @submit.prevent="handleSubmit(onSubmit)" class="form f-w6">
              <ValidationProvider
                name="メールアドレス"
                rules="required|email|min:6"
                v-slot="{ errors }"
              >
                <div class="email text-left">
                  <label for="email">メールアドレス</label>
                  <input
                    type="text"
                    class="f-w3 text"
                    v-model="email"
                    id="email-register"
                    @blur="handleBlur()"
                  />
                  <span class="error f-w3">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <ValidationProvider
                name="パスワード"
                rules="required|min:6"
                v-slot="{ errors }"
              >
                <div class="password text-left">
                  <label for="password">パスワード</label>
                  <input type="password" class="f-w3 text" v-model="password" />
                  <span class="error f-w3">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <ValidationProvider
                name="パスワード（確認）"
                rules="required|min:6|confirmed:パスワード"
                v-slot="{ errors }"
              >
                <div class="confirm-password text-left">
                  <label for="confirm-password">パスワード（確認）</label>
                  <input
                    type="password"
                    class="f-w3 text"
                    v-model="confirmPassword"
                  />
                  <span class="error f-w3">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <button type="submit" class="f-w3">登録する</button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </AppContainer>
  </div>
</template>

<script>
import HeaderCommon from "@/views/Common/HeaderCommon";
import ButtonBack from "@/views/Common/ButtonBack";
import Cookies from "js-cookie";

export default {
  name: "EmailRegister",
  components: {
    HeaderCommon,
    ButtonBack
  },
  metaInfo() {
    return {
      title: "メールを登録する",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: ""
    };
  },
  mounted() {
    if (this.$route.query.code) {
      Cookies.set("invitation_code", this.$route.query.code);
    } else {
      if (Cookies.get("invitation_code")) {
        Cookies.remove("invitation_code");
      }
    }
  },
  methods: {
    handleBlur() {
      this.email = this.email.trim();
    },
    async onSubmit() {
      var params = {
        email: this.email,
        password: this.password,
        password_confirmation: this.confirmPassword
      };

      if (this.$route.query.code) {
        params.invitation_code = this.$route.query.code;
      }

      this.$root.$refs.loading.start();
      await this.$store
        .dispatch("register/register", params)
        .then(() => {
          this.$root.$refs.loading.finish();
          if (localStorage.getItem("step")) {
            localStorage.removeItem("step");
          }
          if (this.$route.query.code) {
            this.$router.push({
              name: "TopRegister",
              query: { code: this.$route.query.code, register: "success" }
            });
          } else {
            this.$router.push({
              name: "TopRegister",
              query: { register: "success" }
            });
          }
        })
        .catch(error => {
          this.$root.$refs.loading.finish();
          throw error;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_fontFamily";
@import "~@/assets/scss/emailRegister";
</style>
