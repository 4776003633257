var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"register-email"}},[_c('AppContainer',{attrs:{"useBackground":true}},[_c('HeaderCommon',{attrs:{"use-logo":true},scopedSlots:_vm._u([{key:"headerButton",fn:function(){return [_c('ButtonBack',{staticClass:"btn-back position-absolute"})]},proxy:true}])}),_c('div',{staticClass:"email-register"},[_c('img',{staticClass:"logo",attrs:{"alt":"logo-login-email","src":require("@/assets/image/icon-mail.svg")}}),_c('div',{staticClass:"email-register__content"},[_c('ValidationObserver',{ref:"anyName",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form f-w6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"メールアドレス","rules":"required|email|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"email text-left"},[_c('label',{attrs:{"for":"email"}},[_vm._v("メールアドレス")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"f-w3 text",attrs:{"type":"text","id":"email-register"},domProps:{"value":(_vm.email)},on:{"blur":function($event){return _vm.handleBlur()},"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('span',{staticClass:"error f-w3"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"パスワード","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"password text-left"},[_c('label',{attrs:{"for":"password"}},[_vm._v("パスワード")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"f-w3 text",attrs:{"type":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',{staticClass:"error f-w3"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"パスワード（確認）","rules":"required|min:6|confirmed:パスワード"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"confirm-password text-left"},[_c('label',{attrs:{"for":"confirm-password"}},[_vm._v("パスワード（確認）")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"f-w3 text",attrs:{"type":"password"},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmPassword=$event.target.value}}}),_c('span',{staticClass:"error f-w3"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('button',{staticClass:"f-w3",attrs:{"type":"submit"}},[_vm._v("登録する")])],1)]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }